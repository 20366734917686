/* TODO: simplify the css */

.kiln-part:hover .overlay {
  fill-opacity: 0.2;
}

.kiln-part-hover .overlay {
  fill-opacity: 0.2 !important;
}

.kiln-part-active .overlay {
  fill-opacity: 0.4;
}

.kiln-part-hover.warning .overlay {
  fill: #ba6d00 !important;
  fill-opacity: 0.4 !important;
}

.kiln-part.warning .overlay {
  fill: #ee8b00;
  fill-opacity: 0.4;
}

.kiln-part:hover.warning .overlay {
  fill: #ba6d00;
  fill-opacity: 0.4;
}

.kiln-part-active.warning .overlay {
  fill: #874f00;
  fill-opacity: 0.4;
}

.kiln-part-hover.error .overlay {
  fill: #8c0601 !important;
  fill-opacity: 0.4 !important;
}

.kiln-part.error .overlay {
  fill: #da0901;
  fill-opacity: 0.4;
}

.kiln-part:hover.error .overlay {
  fill: #8c0601;
  fill-opacity: 0.4;
}

.kiln-part-active.error .overlay {
  fill: #590400;
  fill-opacity: 0.4;
}
